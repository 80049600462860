import * as React from 'react'
import Layout from '../components/layout'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { css } from '@emotion/core'


const storeLocation = {
  lat: 48.176251,
  lng: 11.60012
};


export const AnfahrtPage = () => {
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: "AIzaSyDQhjdVCMAsE6IZ9eHADCP_QtZ31CS1lb8",
  });

  return <Layout noFooterMargin={true}>
    <div style={{
      position: 'relative',
      width: "100%",
      height: '100%'
    }}>
      <div css={css`
        position: absolute;
        left: 10px;
        top: 10px;
        background: rgba(0, 0, 0, 0.8);
        border-radius: 8px;
        color: white;
        padding: 10px 20px;
        font-size: 18px;
        z-index: 10;
      `}>
        <div>
          <strong>Unsere Adresse:</strong>
        </div>
        <div style={{margin: '10px 0'}}>
          Blumen Elsdörfer<br />
          Ungererstraße 157<br />
          80805 München
        </div>

        <div>
          <strong>Unsere Öffnungszeiten:</strong>
        </div>
        <div style={{margin: '10px 0'}}>
          Montag bis Freitag, 08:30 bis 17:00 Uhr<br />
          Samstag, 08:30 bis 15:00 Uhr<br />
        </div>
      </div>
      {isLoaded ? <GoogleMap
        mapContainerStyle={{
          minHeight: "600px",
          width: "100%",
          height: '100%'
        }}
        options={{mapTypeControl: false}}
        zoom={12}
        center={storeLocation}
      >
        <Marker
          position={storeLocation}
        />
      </GoogleMap> : null }
    </div>
  </Layout>
}

export default AnfahrtPage;